import React, { useState } from 'react';
import ImageDrKrokowski from '../../components/images/ImageDrKrokowski';
import Layout from '../../components/layout';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Column from '../../components/Column';
import Img from 'gatsby-image';
import Grid from '../../components/Grid';

const DrMedMichaelHKrokowski = ({ data, location, ...props }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [index, setIndex] = useState(0);

	const onClickImage = (image, index) => () => {
		setIndex(index);
		setIsOpen(true);
	};

	const images = data.images.edges.map((edge) => {
		return edge.node.childImageSharp.full.src;
	});

	return (
		<Layout
			location={location}
			title="Dr. med. Michael H. Krokowski"
			noSlider
			{...props}
		>
			<div className="md:float-right md:ml-4 mb-4">
				<figure className="flex flex-col items-center">
					<ImageDrKrokowski />
					<figcaption className="text-center mt-2">
						<strong>Dr. med. Michael H. Krokowski</strong>
					</figcaption>
				</figure>
			</div>

			{isOpen && (
				<Lightbox
					mainSrc={images[index]}
					nextSrc={images[(index + 1) % images.length]}
					prevSrc={
						images[(index + images.length - 1) % images.length]
					}
					onCloseRequest={() => setIsOpen(false)}
					onMovePrevRequest={() =>
						setIndex((index + images.length - 1) % images.length)
					}
					onMoveNextRequest={() =>
						setIndex((index + 1) % images.length)
					}
				/>
			)}
			<p>
				Gesunde Haut und ein gepflegtes, attraktives Erscheinungsbild
				sind Ausdruck von Persönlichkeit und entsprechen dem heutigen
				Zeitgeist der leistungs- und erfolgsorientierten Gesellschaft.
			</p>
			<p>
				Erkrankungen oder schädliche Umwelteinflüsse können die
				Gesundheit und das Erscheinungsbild der Haut massiv
				beeinträchtigen. Dies ist nicht nur unschön - auch die Psyche
				leidet manchmal unter kleinen Makeln, was oft auch die sozialen
				Kontakte beeinträchtigt.
			</p>
			<p>
				Die Verbesserung Ihrer Lebensqualität und Ihres Wohlbefindens
				durch eine gesunde Haut und ein harmonischeres Aussehen ist mein
				persönliches Ziel. Neben der Diagnose und Bewältigung
				dermatologischer Erkrankungen kann eine weiterführende Maßnahme
				ebenfalls ratsam sein - beispielsweise die Behandlung von
				Akne-Narben mittels dermatologischer Lasertherapie oder
				medizinisches Peeling. Ich verkaufe keine Ware - ich höre zu,
				ich berate und ich empfehle sinnvolles.
			</p>
			<p>
				Sie haben spezielle Fragen? Gerne berate ich Sie umfassend in
				einem ausführlichen Gespräch. Rufen Sie einfach in unserer
				Praxis an oder nutzen Sie unser Kontaktformular. Ich werde Ihnen
				helfen!
			</p>
			<p>
				Herzlichst Ihr,
				<br />
				<strong>Dr. med. Michael H. Krokowski</strong>
			</p>

			<div>
				<Grid>
					{data.images.edges.map((image, index) => {
						return (
							<Column
								key={image.node.name}
								md="1/3"
								className="mt-6"
							>
								<div
									className="cursor-pointer"
									onClick={onClickImage(image, index)}
								>
									<Img
										fluid={image.node.childImageSharp.fluid}
									/>
								</div>
							</Column>
						);
					})}
				</Grid>
			</div>

			<h2 className="text-blue mt-20 text-xl border-b border-grey-light pb-3">
				Vita
			</h2>
			<ul>
				<li>Studium der Humanmedizin (Marburg)</li>
				<li>Weiterbildung Chirurgie/DRK Kassel bei Prof. Fuchs</li>
				<li>Facharztausbildung/Hautklinik Kassel bei Prof. Peter</li>
				<li>
					Oberarzt der operativen Dermatologie, Hautklinik der
					medizinischen Hochschule Hannover
				</li>
				<li>
					seit 1995 niedergelassener Facharzt für Dermatologie mit
					Schwerpunkten operative Dermatologie, ästhetische
					Dermatologie und Lasermedizin
				</li>
				<li>
					Zusatzqualifikationen: Allergologe, Phlebologe,
					Dermatologische- Lasertherapie und Kosmetologie und
					Umweltmedizin, Berufsdermatologe, Ambulante Operationen
				</li>
			</ul>
		</Layout>
	);
};

export const query = graphql`
	query DrKrokowskiQuery {
		images: allFile(filter: { relativePath: { glob: "drkrokowski/*" } }) {
			edges {
				node {
					name
					childImageSharp {
						full: fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
						fluid(maxWidth: 240) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;

export default DrMedMichaelHKrokowski;
