import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { useMedia } from 'react-use';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const ImageDrKrokowski = ({ className }) => {
	const isMobile = useMedia('(max-width: 767px)');

	return (
		<StaticQuery
			query={graphql`
				query {
					image: file(relativePath: { eq: "team/dr_krokowski.jpg" }) {
						childImageSharp {
							fixed(width: 200, height: 300, quality: 100) {
								...GatsbyImageSharpFixed
							}

							fluid(maxHeight: 300, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			`}
			render={(data) => {
				if (isMobile) {
					return (
						<Img
							style={{
								width: '100%',
							}}
							className={className}
							fluid={data.image.childImageSharp.fluid}
						/>
					);
				}

				return (
					<Img
						className={className}
						fixed={data.image.childImageSharp.fixed}
					/>
				);
			}}
		/>
	);
};
export default ImageDrKrokowski;
